import React from "react"

import { useImage } from "../../../hooks/useImage"

export const withActivate = Component => ({ name = "Activate", page }) => {
  const { getFluidImage } = useImage()

  const { title, image: originalImage } = page

  const image = getFluidImage(originalImage)

  Component.displayName = name
  return <Component title={title} image={image} />
}
